.cart {
    width: 50rem;
    max-width: 90%;
    margin: 2rem auto;
  }
  
  .cart p {
    text-align: center;
  }
  
  .cart ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .cart li {
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid #00179b;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box3{
    /* From https://css.glass */
background: rgba(49, 49, 49, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(4.5px);
-webkit-backdrop-filter: blur(4.5px);
  }